import { useState, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Sidebar } from 'primereact/sidebar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Input, Errors, Checkbox, Select } from '../entries/FormElements';
import { standardHeaders } from '../entries/utils';
import { useParams } from 'react-router-dom';
import { DealershipContext } from '../contexts';

let roleOptions = [
  { value: 'default', label: 'Default' },
  { value: 'manager', label: 'Manager' },
]

const Form = ({ user, setVisible, leadCategories }) => {

  let { dealership } = useContext(DealershipContext);
  let { dealershipSlug } = useParams();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: user?.name || '',
      position: user?.position || '',
      email: user?.email || '',
      phone: user?.phone || '',
      show_on_website: user?.show_on_website || true,
      exclude_from_google_reviews: user?.exclude_from_google_reviews || false,
      receive_google_review_notifications: user?.receive_google_review_notifications || true,
      skip_invitation: false,
      dealership_users_attributes: {
        0: {
          dealership_id: dealership?.id,
          role: user?.role || 'default',
          can_manage_cars: true,
          can_manage_leads: true,
          can_manage_customers: true,
          can_manage_qr_codes: true,
          can_manage_reviews: true,
          can_manage_warranties: false,
          can_download_leads_csv: false,
          location_ids: [],
          lead_categories: [],
          can_only_see_assigned_leads: false,
          can_be_assigned_to_leads: true,
          bio: '',
          category: '',
          enquiries_disabled: false
        }
      },
    },
  });

  const submit = (data) => {
    setLoading(true);
    let url = `/dealerships/${dealershipSlug}/users`;

    fetch(url, {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({ user: data }),
    }).then((response) => {
      if (response.status === 422) {
        return response.json().then((data) => {
          setLoading(false);
          setErrors(data);
          return Promise.reject(data);
        });
      }
      if (response.status === 500) {
        setLoading(false);
        return Promise.reject();
      }
      return response.json(); // Handle other statuses normally
    })
      .then(() => {
        window.location.reload();
        // setLoading(false);
        // setVisible(false);
      })
  }

  return (
    <form>
      <div className="py-3">
        <Accordion activeIndex={0}>
          <AccordionTab header="Details">
            <div className="row">
              <div className="col">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => <Input type="text" label="Name" placeholder="Name" {...field} />}
                />
              </div>
              <div className="col">
                <Controller
                  name="position"
                  control={control}
                  render={({ field }) => <Input type="text" label="Position" placeholder="Position" {...field} />}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => <Input type="email" label="Email" placeholder="Email" {...field} />}
                />
              </div>
              <div className="col">
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => <Input type="tel" label="Phone" placeholder="Phone" {...field} />}
                />
              </div>
            </div>
            <Controller
              name="show_on_website"
              control={control}
              render={({ field }) => <Checkbox {...field} label="Show on website" wrapperClass="" />}
            />
            <Controller
              name="exclude_from_google_reviews"
              control={control}
              render={({ field }) => <Checkbox {...field} label="Exclude from Google Review" wrapperClass="" />}
            />
            <Controller
              name="receive_google_review_notifications"
              control={control}
              render={({ field }) => <Checkbox {...field} label="Receive Google Review notifications" wrapperClass="" />}
            />
            {!user && (
              <Controller
                name="skip_invitation"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} label="Do not send email invitation" />
                )}
              />
            )}
          </AccordionTab>
          <AccordionTab header="Permissions">
            <Select
              name="role"
              control={control}
              options={roleOptions}
              defaultValue={roleOptions[0]}
              label="Role"
            />
            <div className="row">
              <div className="col-md-3">
                <Controller
                  name="dealership_users_attributes[0].can_manage_cars"
                  control={control}
                  render={({ field }) => <Checkbox {...field} label="Can Manage Cars" />}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name="dealership_users_attributes[0].can_manage_leads"
                  control={control}
                  render={({ field }) => <Checkbox {...field} label="Can Manage Leads" />}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name="dealership_users_attributes[0].can_manage_customers"
                  control={control}
                  render={({ field }) => <Checkbox {...field} label="Can Manage Customers" />}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name="dealership_users_attributes[0].can_manage_qr_codes"
                  control={control}
                  render={({ field }) => <Checkbox {...field} label="Can Manage QR Codes" />}
                />
              </div>
              <div className="col-md-3">
                <Controller
                  name="dealership_users_attributes[0].can_manage_reviews"
                  control={control}
                  render={({ field }) => <Checkbox {...field} label="Can Manage Reviews" />}
                />
              </div>
            </div>
            <Controller
              name="dealership_users_attributes[0].location_ids"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti
                  options={dealership.locations.map(location => ({ value: location.id, label: location.name }))}
                  onChange={(selectedOptions) => {
                    field.onChange(selectedOptions ? selectedOptions.map(option => option.value) : []);
                  }}
                  value={dealership.locations
                    .filter(location => field.value.includes(location.id))
                    .map(location => ({ value: location.id, label: location.name }))
                  }
                  label="Locations"
                  hint="Can only view cars, leads etc from the following locations:"
                />
              )}
            />
            <Controller
              name="dealership_users_attributes[0].lead_categories"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti
                  options={leadCategories.map(cat => ({ value: cat, label: cat }))}
                  onChange={(selectedOptions) => {
                    field.onChange(selectedOptions ? selectedOptions.map(option => option.value) : []);
                  }}
                  value={leadCategories
                    .filter(cat => field.value.includes(cat))
                    .map(cat => ({ value: cat, label: cat }))
                  }
                  label="Lead Categories"
                  hint="Limit the user to ONLY see leads from these categories. If blank user will see all lead categories."
                />
              )}
            />
            <Controller
              name="dealership_users_attributes[0].can_only_see_assigned_leads"
              control={control}
              render={({ field }) => <Checkbox {...field} label="Can only see assigned leads" wrapperClass='' />}
            />
            <Controller
              name="dealership_users_attributes[0].can_be_assigned_to_leads"
              control={control}
              render={({ field }) => <Checkbox {...field} label="Can be assigned to leads" wrapperClass='' />}
            />
          </AccordionTab>
        </Accordion>
      </div>

      <Errors errors={errors} />
      <button
        className={"btn btn-secondary" + (loading ? " disabled" : "")}
        onClick={handleSubmit(submit)}
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Submit'}
      </button>
    </form>
  );
};

export const NewUserSidebar = () => {
  let [visible, setVisible] = useState(false);
  let [loading, setLoading] = useState(true);
  let [leadCategories, setLeadCategories] = useState([]);
  let { dealershipSlug } = useParams();

  useEffect(() => {
    if (!visible) return;

    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/users/new.json`)
      .then(res => res.json())
      .then(data => {
        setLeadCategories(data);
        setLoading(false)
      })
  }, [visible]);

  return (
    <div>
      <button
        className="dropdown-item"
        onClick={() => setVisible(true)}
      >
        New User
        <div className="badge badge-danger ml-2">Beta</div>
      </button>
      <Sidebar
        header="New User"
        visible={visible}
        style={{ width: '1000px' }}
        onHide={() => setVisible(false)}
        position="right"
      >
        {loading ? 'Loading...' : <Form setVisible={setVisible} leadCategories={leadCategories} />}
      </Sidebar>
    </div>
  );
}

export default Form;